(function () {
	const navigation = document.querySelector('.navigation');

	if (document.querySelectorAll('.navigation').length > 0) {
		const topItemsWithDropdown = [].slice.call(navigation.querySelectorAll('.navigation__link-item_bottom.navigation__link-item_dropdown'));
		const topItemsWithDropdownLink = [].slice.call(navigation.querySelectorAll('.navigation__link_bottom.navigation__link_dropdown'));

		topItemsWithDropdown.forEach((item, index) => {
			item.setAttribute('aria-haspopup', 'true');
			item.setAttribute('aria-expanded', 'false');

			item.addEventListener('mouseover', event => {
				event.preventDefault();
				topItemsWithDropdown.forEach((cur, indexCur) => {
					if (index === indexCur) {
						cur.classList.toggle('active');
						if (cur.hasAttribute('aria-expanded')) {
							if (cur.getAttribute('aria-expanded') === 'true') {
								cur.setAttribute('aria-expanded', 'false');
							} else if (cur.getAttribute('aria-expanded') === 'false') {
								cur.setAttribute('aria-expanded', 'true');
							}
						}
					} else {
						cur.classList.remove('active');
						if (cur.hasAttribute('aria-expanded')) {
							cur.setAttribute('aria-expanded', 'false');
						}
					}
				});
			});

			item.addEventListener('mouseout', () => {
				item.classList.remove('active');
				if (item.hasAttribute('aria-expanded')) {
					item.setAttribute('aria-expanded', 'false');
				}
			});
		});

		document.addEventListener('keydown', event => {
			if (event.keyCode === 32) {
				topItemsWithDropdownLink.forEach((topItem, index) => {
					if (event.target === topItem) {
						event.preventDefault();
						topItemsWithDropdownLink.forEach((cur, indexCur) => {
							if (index === indexCur) {
								cur.parentElement.parentElement.classList.toggle('active');
								if (cur.parentElement.parentElement.hasAttribute('aria-expanded')) {
									cur.parentElement.parentElement.setAttribute('aria-expanded', 'true');
								}
							} else {
								cur.parentElement.parentElement.classList.remove('active');
								if (cur.parentElement.parentElement.hasAttribute('aria-expanded')) {
									cur.parentElement.parentElement.setAttribute('aria-expanded', 'false');
								}
							}
						});
					}
				});
			} else if (event.keyCode === 9) {
				setTimeout(() => {
					topItemsWithDropdownLink.forEach(topItem => {
						if (document.activeElement === topItem) {
							if (topItem.parentElement.parentElement.classList.contains('active') === false) {
								topItemsWithDropdown.forEach(item => {
									item.classList.remove('active');
								});
							}
						}
					});
					if (document.activeElement.classList.contains('navigation__link_dropdown') === false
						&& document.activeElement.classList.contains('navigation__dropdown-link') === false
						&& document.activeElement.classList.contains('navigation__dropdown-bottom-link') === false) {
						topItemsWithDropdown.forEach(item => {
							item.classList.remove('active');
							if (item.hasAttribute('aria-expanded')) {
								item.setAttribute('aria-expanded', 'false');
							}
						});
					}
				}, 0);
			}
		});

		document.addEventListener('click', event => {
			if (topItemsWithDropdownLink.includes(event.target) === false) {
				topItemsWithDropdown.forEach(item => {
					item.classList.remove('active');
					if (item.hasAttribute('aria-expanded')) {
						item.setAttribute('aria-expanded', 'false');
					}
				});
			}
		});
	}
}());
