// eslint-disable-next-line
import moment from 'moment';
import {clone} from 'lodash';
import fetch from 'unfetch';
import SlimSelect from 'slim-select';
import 'slim-select/dist/slimselect.css';
import Pikaday from 'pikaday';
import 'pikaday/css/pikaday.css';

function initSearch() {
	const INITIAL_PARAMS = {
		pageSize: 9,
		pageNumber: 0,
		sectors: [],
		deliveryFormats: [],
		jobTitles: [],
		startDate: null,
		endDate: null,
		priceFrom: null,
		priceTo: null,
		orderBy: null,
		orderDirection: null
	};

	const INITIAL_FILTER_PARAMS = {
		sectors: [],
		deliveryFormats: [],
		jobTitles: [],
		startDate: null,
		endDate: null,
		priceFrom: null,
		priceTo: null
	};

	const INITIAL_SORTING_PARAMS = {
		orderBy: null,
		orderDirection: null
	};

	const isTopicSearch = document.querySelector('.js-topic-search') !== null;

	if (isTopicSearch) {
		const topicSearchContainer = document.querySelector('.js-topic-search');
		const topicSector = topicSearchContainer.getAttribute('data-sector-id') || null;
		const topicFormat = topicSearchContainer.getAttribute('data-delivery-format-id') || null;

		const topicFilter = document.querySelector('.js-topic-filter');
		const header = topicFilter.querySelector('.topic-filter__header');
		const closeBtn = topicFilter.querySelector('.topic-filter__header-close');

		INITIAL_PARAMS.orderBy = 'date';
		INITIAL_PARAMS.orderDirection = 'ascending';

		INITIAL_SORTING_PARAMS.orderBy = 'date';
		INITIAL_SORTING_PARAMS.orderDirection = 'ascending';

		header.addEventListener('click', () => {
			topicFilter.classList.add('topic-filter_open');
		});

		closeBtn.addEventListener('click', e => {
			e.stopPropagation();
			topicFilter.classList.remove('topic-filter_open');
		});

		if (topicFormat !== null) {
			INITIAL_FILTER_PARAMS.deliveryFormats.push(topicFormat);
			INITIAL_PARAMS.deliveryFormats.push(topicFormat);
		}

		if (topicSector !== null) {
			INITIAL_FILTER_PARAMS.sectors.push(topicSector);
			INITIAL_PARAMS.sectors.push(topicSector);
		}
	}

	let errorMessageShown = false;

	let params = clone(INITIAL_PARAMS);
	const filterParams = clone(INITIAL_FILTER_PARAMS);
	const sortingParams = clone(INITIAL_SORTING_PARAMS);

	const tilesContainer = document.querySelector('.course-search__tiles-container');
	const courseSearchContainer = document.querySelector('.course-search__container');

	const sectorField = document.getElementById('course-sector');
	const formatField = document.getElementById('course-format');
	const jobField = document.getElementById('course-job');
	const sortingField = document.getElementById('course-sorting');

	if (sectorField !== null) {
		if (isTopicSearch) {
			const inputs = sectorField.querySelectorAll('.topic-filter__facet-checkbox');

			inputs.forEach(cb => {
				cb.addEventListener('change', () => {
					if (cb.checked) {
						filterParams.sectors.push(cb.value);
					} else {
						filterParams.sectors = filterParams.sectors.filter(item => item !== cb.value);
					}
				});
			});
		} else {
			const sectorSelector = new SlimSelect({
				select: sectorField,
				onChange: () => {
					filterParams.sectors = sectorSelector.selected();
				}
			});
		}
	}

	if (formatField !== null) {
		if (isTopicSearch) {
			const inputs = formatField.querySelectorAll('.topic-filter__facet-checkbox');

			inputs.forEach(cb => {
				cb.addEventListener('change', () => {
					if (cb.checked) {
						filterParams.deliveryFormats.push(cb.value);
					} else {
						filterParams.deliveryFormats = filterParams.deliveryFormats.filter(item => item !== cb.value);
					}
				});
			});
		} else {
			const formatSelector = new SlimSelect({
				select: formatField,
				onChange: () => {
					filterParams.deliveryFormats = formatSelector.selected();
				}
			});
		}
	}

	if (jobField !== null) {
		if (isTopicSearch) {
			const inputs = jobField.querySelectorAll('.topic-filter__facet-checkbox');

			inputs.forEach(cb => {
				cb.addEventListener('change', () => {
					if (cb.checked) {
						filterParams.jobTitles.push(cb.value);
					} else {
						filterParams.jobTitles = filterParams.jobTitles.filter(item => item !== cb.value);
					}
				});
			});
		} else {
			const jobSelector = new SlimSelect({
				select: jobField,
				onChange: () => {
					filterParams.jobTitles = jobSelector.selected();
				}
			});
		}
	}

	const endDateSelector = document.getElementById('course-end-date-picker');
	const startDateSelector = document.getElementById('course-start-date-picker');

	// eslint-disable-next-line
	const endDatePicker = new Pikaday({
		field: endDateSelector,
		minDate: new Date(),
		format: 'DD/MM/YYYY',
		onSelect: () => {
			filterParams.endDate = moment(endDatePicker.getDate()).format('YYYY-MM-DD');
		}
	});

	// eslint-disable-next-line
	const startDatePicker = new Pikaday({
		field: startDateSelector,
		minDate: new Date(),
		format: 'DD/MM/YYYY',
		onSelect: () => {
			endDatePicker.config({minDate: startDatePicker.getDate()});
			filterParams.startDate = moment(startDatePicker.getDate()).format('YYYY-MM-DD');
			if (endDatePicker.getDate() < startDatePicker.getDate()) {
				endDatePicker.setDate(null);
				filterParams.endDate = null;
			}
		}
	});

	startDateSelector.addEventListener('keydown', e => {
		if (e.keyCode === 8 || e.keyCode === 46) {
			startDateSelector.value = null;
			startDatePicker.setDate(null);
			filterParams.startDate = null;
			endDatePicker.setMin(new Date());
		}
	});

	endDateSelector.addEventListener('keydown', e => {
		if (e.keyCode === 8 || e.keyCode === 46) {
			endDateSelector.value = null;
			endDatePicker.setDate(null);
			filterParams.endDate = null;
		}
	});

	const minPrice = document.getElementById('course-min-price');

	minPrice.addEventListener('change', () => {
		filterParams.priceFrom = minPrice.value || null;
	});

	const maxPrice = document.getElementById('course-max-price');

	maxPrice.addEventListener('change', () => {
		filterParams.priceTo = maxPrice.value || null;
	});

	const buildSearchParams = searchParams => {
		let qs = '';

		Object.keys(searchParams).forEach(key => {
			const value = searchParams[key];

			if (value === null || value.length < 1 || value === undefined) {
				return;
			}

			if (Array.isArray(value)) {
				value.forEach(v => {
					qs += encodeURIComponent(key) + '=' + encodeURIComponent(v) + '&';
				});
			} else {
				qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
			}
		});

		if (qs.length > 0) {
			qs = qs.substring(0, qs.length - 1);
		}

		return qs;
	};

	const clearTilesContainer = container => {
		container.innerHTML = '';

		if (document.querySelector('.js-show-more-courses') !== null) {
			const btnContainer = document.querySelector('.js-show-more-courses').parentNode;

			btnContainer.parentNode.removeChild(btnContainer);
		}
	};

	const addCourseTiles = (container, tiles) => {
		if (errorMessageShown) {
			clearTilesContainer(container);
			errorMessageShown = false;
		}

		tiles.forEach(t => {
			const tile = document.createElement('div');

			tile.classList.add('course-tile');
			tile.classList.add('course-tile_search');
			/* eslint-disable */
			tile.innerHTML = `
				<div class="course-tile__media">
          ${t.ImageUrl !== null
            ? `<img class="course-tile__image" src="${t.ImageUrl}" alt="${t.ImageAltText}">`
            : ''
          }
				</div>
				<div class="course-tile__content-container">
					${t.DeliveryType !== null && t.DeliveryType !== undefined && t.DeliveryType !== ''
						? `<div class="course-tile__type">
							${t.DeliveryType}
							</div>`
						: ''
					}
					<div class="course-tile__title">
						${t.Title}
					</div>
					<div class="course-tile__info">
						${t.CourseStartDate != null && t.CourseStartDate !== undefined && t.CourseStartDate !== ''
							? `<div class="course-tile__info-item course-tile__info-item_date">
								${moment(t.CourseStartDate).format('DD/MM/YYYY')}
								</div>`
							: ``
						}
						${t.Duration != null && t.Duration !== undefined && t.Duration !== ''
							? `<div class="course-tile__info-item course-tile__info-item_duration">
								${t.Duration}
								</div>`
							: ``
						}

						${t.Level !== null && t.Level !== undefined && t.Level !== ''
							? `<div class="course-tile__info-item course-tile__info-item_level">
								${t.Level}
							</div>`
							: ''
						}
					</div>
					${t.Price !== null && t.Price !== undefined && t.Price !== ''
						? `
						<div class="course-tile__bottom-container">
							<div class="course-tile__info-item course-tile__info-item_price">
								${t.Price}
							</div>
							<a class="course-tile__cta" href="${t.Url}">
								Read more
							</a>
						</div>`
						: `
						<div class="course-tile__bottom-container course-tile__bottom-container_left">
							<a class="course-tile__cta" href="${t.Url}">
								Read more
							</a>
						</div>
						`
					}
				</div>`;
			/* eslint-enable */
			container.appendChild(tile);
		});
	};

	const addMessage = (message, container) => {
		clearTilesContainer(container);

		const msg = document.createElement('div');

		msg.classList.add('course-search__empty-msg');
		msg.innerHTML = `${message}`;
		container.appendChild(msg);
	};

	const fetchSearchResults = searchParams => {
		courseSearchContainer.classList.add('course-search__container_loading');

		fetch(`/Umbraco/Api/CourseSearch/List?${searchParams}`)
			.then(r => r.json())
			.then(data => {
				const {Courses, HasMoreRecords} = data;

				if (Courses.length > 0) {
					addCourseTiles(tilesContainer, Courses);
				} else {
					addMessage('Sorry, no results were found matching that criteria. Please try changing the filters.', tilesContainer);
				}

				if (HasMoreRecords === false) {
					if (document.querySelector('.js-show-more-courses') !== null) {
						const btnContainer = document.querySelector('.js-show-more-courses').parentNode;

						btnContainer.parentNode.removeChild(btnContainer);
					}
				} else if (document.querySelector('.js-show-more-courses') === null) {
					const container = document.querySelector('.course-search__container');
					const btn = document.createElement('button');
					const buttonContainer = document.createElement('div');

					btn.addEventListener('click', () => {
						params.pageNumber += 1;
						fetchSearchResults(buildSearchParams(params));
					});

					buttonContainer.classList.add('course-search__cta-container');
					btn.classList.add('course-search__show-more');
					btn.classList.add('js-show-more-courses');
					btn.innerHTML = 'Show more';

					buttonContainer.appendChild(btn);
					container.appendChild(buttonContainer);
				}

				courseSearchContainer.classList.remove('course-search__container_loading');
			})
			.catch(() => {
				courseSearchContainer.classList.remove('course-search__container_loading');
				addMessage('Failed to load courses. Please try again later', tilesContainer);
				errorMessageShown = true;
			});
	};

	const applyBtnTrigger = () => {
		params = clone(INITIAL_PARAMS);
		params = Object.assign(params, filterParams, sortingParams);

		clearTilesContainer(tilesContainer);
		fetchSearchResults(buildSearchParams(params));
	};

	const applyBtn = document.querySelector('.js-apply-course-filters');

	applyBtn.addEventListener('click', applyBtnTrigger);

	if (sortingField !== null) {
		const sortingSelector = new SlimSelect({
			select: sortingField,
			showSearch: false,
			onChange: () => {
				switch (sortingSelector.selected()) {
					case 'none':
						sortingParams.orderBy = null;
						sortingParams.orderDirection = null;
						break;
					case 'da':
						sortingParams.orderBy = 'date';
						sortingParams.orderDirection = 'ascending';
						break;
					case 'dd':
						sortingParams.orderBy = 'date';
						sortingParams.orderDirection = 'descending';
						break;
					case 'az':
						sortingParams.orderBy = 'name';
						sortingParams.orderDirection = 'ascending';
						break;
					case 'za':
						sortingParams.orderBy = 'name';
						sortingParams.orderDirection = 'descending';
						break;
					case 'cf':
						sortingParams.orderBy = 'price';
						sortingParams.orderDirection = 'ascending';
						break;
					case 'ef':
						sortingParams.orderBy = 'price';
						sortingParams.orderDirection = 'descending';
						break;
				}
				params.pageNumber = 0;
				params = Object.assign(params, filterParams, sortingParams);
				clearTilesContainer(tilesContainer);
				fetchSearchResults(buildSearchParams(params));
			}
		});
	}

	fetchSearchResults(buildSearchParams(INITIAL_PARAMS));
}

if (document.readyState !== 'loading') {
	if (!(document.querySelector('.js-course-search') === null)) {
		initSearch();
	}
} else {
	document.addEventListener('DOMContentLoaded', () => {
		if (!(document.querySelector('.js-course-search') === null)) {
			initSearch();
		}
	});
}
