import 'modal-video/css/modal-video.min.css';
import ModalVideo from 'modal-video';

function initializePopups() {
	// eslint-disable-next-line
	new ModalVideo('.js-video-youtube', {
		channel: 'youtube',
		options: {
			autoplay: 1
		}
	});
}

if (['complete', 'loaded', 'interactive'].includes(document.readyState)) {
	initializePopups();
} else {
	document.addEventListener('DOMContentLoaded', () => {
		initializePopups();
	});
}
