(function () {
	const carouselItems = Array.from(document.querySelectorAll('.hero-carousel__item'));

	let replaced = false;

	carouselItems.forEach(item => {
		if (!replaced && !item.classList.contains('tns-slide-cloned')) {
			const title = item.querySelector('.hero-carousel__title');
			const newTitle = document.createElement('h1');

			newTitle.innerHTML = title.innerHTML;
			title.parentNode.replaceChild(newTitle, title);
			replaced = true;
		}
	});
}());
