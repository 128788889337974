window.addEventListener('load', () => {
	const searchInput = document.querySelector('.navigation__search-input');

	if (searchInput) {
		const ACTIVE_CLASS = 'navigation__search-wrapper-inner_opened';
		const searchInputWrapperInner = document.querySelector('.navigation__search-wrapper-inner');
		const searchInputWrapperInnerWidth = searchInputWrapperInner.getBoundingClientRect().width;
		const closeIcon = document.querySelector('.navigation__close-icon');
		const navMenuBottom = document.querySelector('.navigation__menu-bottom');
		const navMenuBottomWidth = navMenuBottom.getBoundingClientRect().width;

		if (searchInput) {
			searchInput.addEventListener('focus', () => {
				searchInputWrapperInner.classList.add(ACTIVE_CLASS);
				searchInputWrapperInner.style.width = `${navMenuBottomWidth}px`;
			});

			searchInput.addEventListener('blur', () => {
				searchInputWrapperInner.classList.remove(ACTIVE_CLASS);
				searchInputWrapperInner.style.width = `${searchInputWrapperInnerWidth}px`;
			});

			closeIcon.addEventListener('click', () => {
				searchInputWrapperInner.classList.remove(ACTIVE_CLASS);
				searchInputWrapperInner.style.width = `${searchInputWrapperInnerWidth}px`;
			});
		}
	}
});
