(function () {
	const btn = document.querySelector('.js-partners-more');
	const TABLET_LANDSCAPE = 824;

	if (!btn) {
		return;
	}

	const partners = document.querySelectorAll('.partners-block__logo-icon');

	const hideShowBtn = () => {
		if (partners.length <= 3) {
			btn.style.display = 'none';
		} else if (partners.length > 3 && window.innerWidth < TABLET_LANDSCAPE) {
			btn.style.display = 'block';
		} else if (partners.length > 6 && window.innerWidth >= TABLET_LANDSCAPE) {
			btn.style.display = 'block';
		} else {
			btn.style.display = 'none';
		}
	};

	hideShowBtn();
	window.addEventListener('resize', hideShowBtn);

	btn.addEventListener('click', () => {
		const icons = document.querySelector('.partners-block__logo-icons');
		const ALL_PARTNERS_CLASS = 'partners-block__logo-icons_full';
		const btnText = btn.querySelector('.partners-block__more-btn-text');

		icons.classList.toggle(ALL_PARTNERS_CLASS);

		if (icons.classList.value.includes(ALL_PARTNERS_CLASS)) {
			btnText.innerHTML = 'Show less';
		} else {
			btnText.innerHTML = 'Show more';
		}
	});
}());
