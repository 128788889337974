import fetch from 'unfetch';
import {loadStripe} from '@stripe/stripe-js';
import validate from './validate';

(function () {
	const detailsBtn = document.querySelector('.js-details-btn');

	if (!detailsBtn) {
		return;
	}

	const apiUrl = detailsBtn.dataset.url;
	const inputPlaces = document.querySelector('.course-booking__places-input');
	const numberOfPlacesButtons = document.querySelectorAll('.course-booking__places-btn');
	const incrementButton = document.querySelector('.course-booking__places-btn_incr');
	const maxPlacesError = document.querySelector('.course-booking__max-places-error');
	const minPlaces = Number(inputPlaces.getAttribute('min'));
	const maxPlaces = Number(inputPlaces.getAttribute('max'));
	const attendeeTypeBlock = document.querySelector('.js-attendee-type');
	const costs = document.getElementById('costs');
	const courseCosts = document.querySelectorAll('#courseCosts');
	const tiketsQuantity = document.querySelectorAll('.js-tikets-quantity');
	const selectedAttendeeType = document.getElementById('selectedAttendeeType');
	const selectedAddressType = document.getElementById('selectedAddressType');
	const validatePinBtn = document.querySelector('.js-validate-pin');
	const step2Btn = document.querySelector('.js-go-to-step-2');
	const backToStep1Btn = document.querySelector('.js-back-to-step-1');
	const backToStep2Btn = document.querySelector('.js-back-to-step-2');
	const backToDetailsBtn = document.querySelector('.js-back-to-details-btn');
	const errorMsg = document.querySelector('.course-booking__error-msg');
	const overlay = document.querySelector('.course-booking__loading-overlay');
	const ACTIVE_STEP_CLASS = 'course-booking__stepper-item_active';
	const SUCCESS_STATUS = 200;
	const ERROR_MSG = 'Something went wrong, please try again later';
	const stripeErrorMessage = document.querySelector('.js-stripe-general-error-message');
	const deliveryAddress = document.querySelector('.course-booking__delivery-address');
	const productId = document.getElementById('productId');
	const apiBaseUrl = apiUrl.indexOf('ProductPurchase') > -1 ? '/umbraco/Api/ProductPurchase' : '/umbraco/Api/Booking';

	let paymentIntentId,
		clientSecret,
		publishableKey,
		stripe,
		cardNumber,
		orderId,
		freeCourse;

	function OnNumbersOfPlacesChange(element) {
		const increment = element.classList.contains('course-booking__places-btn_incr');

		if (increment) {
			if (inputPlaces.value < maxPlaces) {
				inputPlaces.value = +inputPlaces.value + 1;

				if (+inputPlaces.value === maxPlaces) {
					incrementButton.disabled = true;
					maxPlacesError.style.display = 'block';
				}
			}
		} else if (inputPlaces.value > minPlaces) {
			inputPlaces.value = +inputPlaces.value - 1;

			if (+inputPlaces.value < maxPlaces) {
				incrementButton.disabled = false;
				maxPlacesError.style.display = 'none';
			}
		}

		courseCosts.forEach(cost => {
			cost.innerHTML = `£${(+inputPlaces.value * parseFloat(costs.value)).toFixed(2)}`;
		});

		tiketsQuantity.forEach(quantity => {
			quantity.innerHTML = inputPlaces.value;
		});

		if (attendeeTypeBlock) {
			attendeeTypeBlock.style.display = +inputPlaces.value === 1 ? 'block' : 'none';
		}
	}

	function OnAttendeeRadioStateChange() {
		const {value} = document.querySelector('input[name="attendeeType"]:checked');

		selectedAttendeeType.value = value;
	}

	function OnAddressRadioStateChange() {
		const inputValue = document.querySelector('input[name="addressType"]:checked').value;

		selectedAddressType.value = inputValue;

		deliveryAddress.style.display = inputValue === 'False' ? 'block' : 'none';
	}

	function setActiveStep(step) {
		const steps = Array.from(document.querySelectorAll('.course-booking__stepper-item'));
		const activeStepIndex = steps.findIndex(s => s.getAttribute('data-step-title') === step);

		steps.forEach((s, index) => {
			s.classList.remove(ACTIVE_STEP_CLASS);

			if (index <= activeStepIndex) {
				s.classList.add(ACTIVE_STEP_CLASS);
			}
		});
	}

	function enableLoading() {
		overlay.style.display = 'block';
	}

	function disableLoading() {
		overlay.style.display = 'none';
	}

	function getInputValue(input) {
		if (input) {
			return input.value;
		}

		return '';
	}

	function orderFailed(paymentIntent) {
		const requestBody = {
			paymentIntent
		};

		fetch(apiBaseUrl + '/OrderFailed', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify(requestBody)
		})
			.then(() => {
				disableLoading();
			});
	}

	setActiveStep('booking-summary');

	if (validatePinBtn) {
		const validateApiUrl = validatePinBtn.dataset.url;

		const validatePin = () => {
			const pin = document.querySelector('.js-pin');
			const courseId = document.getElementById('courseId').value;
			const ticketId = document.getElementById('selectedTicket').value;
			const discountId = document.getElementById('selectedDiscount').value;
			const pinError = document.querySelector('.js-pin-error');

			const requestValidationBody = {
				pin: pin.value,
				courseId,
                ticketId,
                discountId
			};

			pin.classList.remove('course-booking__input_error');
			pinError.innerHTML = '';

			if (pin.value) {
				enableLoading();

				fetch(validateApiUrl, {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					},
					credentials: 'include',
					body: JSON.stringify(requestValidationBody)
				})
					.then(res => res.json())
					.then(data => {
						if (data.status === 'Allow') {
							document.querySelector('.js-step-1').style.display = 'none';
							document.querySelector('.js-step-2').style.display = 'block';

							setActiveStep('details');
						} else {
							pin.classList.add('course-booking__input_error');
							pinError.innerHTML = 'Incorrect pin';
						}

						disableLoading();
					});
			} else {
				pin.classList.add('course-booking__input_error');
				pinError.innerHTML = 'Please fill this field';
			}
		};

		validatePinBtn.addEventListener('click', validatePin);
	}

	if (step2Btn) {
		const goToStep2 = () => {
			document.querySelector('.js-step-1').style.display = 'none';
			document.querySelector('.js-step-2').style.display = 'block';

			setActiveStep('details');
		};

		step2Btn.addEventListener('click', goToStep2);
	}

	if (backToStep1Btn) {
		const backToStep1 = () => {
			document.querySelector('.js-step-2').style.display = 'none';
			document.querySelector('.js-step-1').style.display = 'block';

			setActiveStep('booking-summary');
		};

		backToStep1Btn.addEventListener('click', backToStep1);
	}

	if (backToStep2Btn) {
		const backToStep2 = () => {
			document.querySelector('.js-step-3').style.display = 'none';
			document.querySelector('.js-step-2').style.display = 'block';

			setActiveStep('details');
		};

		backToStep2Btn.addEventListener('click', backToStep2);
	}

	if (detailsBtn) {
		const createOrder = () => {
			const requestBody = {
				firstName: document.getElementById('first-name').value,
				lastName: document.getElementById('last-name').value,
				email: document.getElementById('email').value,
				company: document.getElementById('company').value,
				jobTitle: document.getElementById('job-title').value,
				phone: document.getElementById('phone').value,
				address: document.getElementById('address').value,
				city: document.getElementById('city').value,
				country: document.getElementById('country').value,
				postcode: document.getElementById('postcode').value,
				selectedDate: getInputValue(document.getElementById('selectedDate')),
				selectedTicket: getInputValue(document.getElementById('selectedTicket')),
				discountId: getInputValue(document.getElementById('selectedDiscount')),
				courseId: getInputValue(document.getElementById('courseId')),
				coursePageUrl: getInputValue(document.getElementById('coursePageUrl')),
				numberOfPlaces: inputPlaces.value,
				attendeeType: getInputValue(selectedAttendeeType),
				paymentIntentId,

				deliveryAddress: getInputValue(document.getElementById('shippingAddress')),
				deliveryCity: getInputValue(document.getElementById('shippingCity')),
				deliveryCountry: getInputValue(document.getElementById('shippingCountry')),
				deliveryPostcode: getInputValue(document.getElementById('shippingPostcode')),
				isDeliveryEqual: getInputValue(selectedAddressType),
				productId: getInputValue(productId),
				numberOfProducts: inputPlaces.value
			};

			if (document.querySelector('.js-pin') != null) {
				requestBody.pin = document.querySelector('.js-pin').value;
			}

			errorMsg.innerHTML = '';
			enableLoading();

			fetch(apiUrl, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				credentials: 'include',
				body: JSON.stringify(requestBody)
      })
        .then(res => {
          if (res.status === SUCCESS_STATUS) {
            return res.json();
          }

          errorMsg.innerHTML = ERROR_MSG;
          disableLoading();
				})
        .then(async data => {
          disableLoading();
          if (data.ErrorMessage !== null && data.ErrorMessage !== "") {
            if (data.ErrorMessage == "NOT_LOGGED_IN") {
              window.location.href = "/login";
            }
            else {
              errorMsg.innerHTML = data.ErrorMessage;
            }
          }
          else {
            paymentIntentId = data.Id;
            clientSecret = data.ClientSecret;
            publishableKey = data.PublishableKey;
            orderId = data.OrderId;
            freeCourse = data.FreeCourse;

            if (freeCourse) {
              fetch(apiBaseUrl + '/CompleteFreeOrder', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(data)
              })
                .then(response => {
                  if (response.status >= 200 && response.status < 300) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(response.statusText));
                })
                .then(() => {
                  window.location.replace(
                    '/courses/thanks-for-course-booking?id=' + orderId
                  );
                })
                .catch(e => {
                  stripeErrorMessage.innerHTML = e;
                  disableLoading();
                });
            } else {
              document.querySelector('.js-step-3').style.display = 'block';
              document.querySelector('.js-step-2').style.display = 'none';
              setActiveStep('payment');

              stripe = await loadStripe(publishableKey);

              const elements = stripe.elements();

              cardNumber = elements.create('cardNumber');
              cardNumber.mount('#card-number');

              const cardExpiry = elements.create('cardExpiry');

              cardExpiry.mount('#card-expiry');

              const cardCvc = elements.create('cardCvc');

              cardCvc.mount('#card-cvc');
              disableLoading();
            }
          }
				})
				.catch(() => {
					errorMsg.innerHTML = ERROR_MSG;
					disableLoading();
				});
		};

		detailsBtn.addEventListener('click', () => {
			const {isValid} = validate('form-details');

			if (isValid) {
				createOrder();
			} else {
				window.scrollTo({
					top: document.getElementsByClassName('course-booking__error-input')[0].getBoundingClientRect().y,
					behavior: 'smooth'
				});
			}
		});

		const cardButton = document.querySelector('.js-course-btn');

		if (cardButton) {
			cardButton.addEventListener('click', () => {
				const {isValid} = validate('form-payment');

				if (isValid) {
					enableLoading();

					const cardErrors = document.querySelectorAll('.js-stripe-card-error');

					cardErrors.forEach(element => {
						element.style.display = 'none';
					});

					document.querySelector('.js-input-card-number-error').classList.remove('course-booking__input_error');
					document.querySelector('.js-input-card-expiry-error').classList.remove('course-booking__input_error');
					document.querySelector('.js-input-card-cvc-error').classList.remove('course-booking__input_error');

					stripe
						.handleCardPayment(clientSecret, cardNumber, {
							payment_method_data: {
								billing_details: {
									name:
										document.getElementById('first-name').value
										+ ' '
										+ document.getElementById('last-name').value,
									email: document.getElementById('email').value,
									phone: document.getElementById('phone').value,
									address: {
										city: document.getElementById('city').value,
										country: document.getElementById('country').value,
										line1: document.getElementById('address').value,
										line2: '',
										postal_code: document.getElementById('postcode').value
									}
								}
							}
						})
						.then(result => {
							if (result.error) {
								if (result.error.type === 'validation_error') {
									if (result.error.code.indexOf('number') > 0) {
										document.querySelector('.js-input-card-number-error').classList.add('course-booking__input_error');
										document.querySelector('.js-stripe-card-number-error').style.display = 'block';
										document.querySelector('.js-stripe-card-number-error').innerHTML = result.error.message;
									} else if (result.error.code.indexOf('expiry') > 0) {
										document.querySelector('.js-input-card-expiry-error').classList.add('course-booking__input_error');
										document.querySelector('.js-stripe-card-expiry-error').style.display = 'block';
										document.querySelector('.js-stripe-card-expiry-error').innerHTML = result.error.message;
									} else if (result.error.code.indexOf('cvc') > 0) {
										document.querySelector('.js-input-card-cvc-error').classList.add('course-booking__input_error');
										document.querySelector('.js-stripe-card-cvc-error').style.display = 'block';
										document.querySelector('.js-stripe-card-cvc-error').innerHTML = result.error.message;
									} else {
										stripeErrorMessage.innerHTML = result.error.message;
									}
								} else {
									stripeErrorMessage.innerHTML = result.error.message;
								}

								orderFailed(paymentIntentId);
							} else if (
								result.paymentIntent
								&& result.paymentIntent.status === 'succeeded'
							) {
								const data = {
									paymentIntent: paymentIntentId
								};

								fetch(apiBaseUrl + '/CompleteOrder', {
									method: 'POST',
									headers: {
										Accept: 'application/json',
										'Content-Type': 'application/json'
									},
									credentials: 'include',
									body: JSON.stringify(data)
								})
									.then(response => {
										if (response.status >= 200 && response.status < 300) {
											return Promise.resolve();
										}
										return Promise.reject(new Error(response.statusText));
									})
									.then(() => {
										window.location.replace(
											'/courses/thanks-for-course-booking?id=' + orderId
										);
									})
									.catch(e => {
										stripeErrorMessage.innerHTML = e;
										orderFailed(paymentIntentId);
									});
							} else {
								stripeErrorMessage.innerHTML = 'Error during checkout';
								orderFailed(paymentIntentId);
							}
						})
						.catch(() => {
							stripeErrorMessage.innerHTML = 'Error during checkout';
							orderFailed(paymentIntentId);
						});
				}
			});
		}

		if (numberOfPlacesButtons) {
			numberOfPlacesButtons.forEach(element => {
				element.addEventListener('click', () => OnNumbersOfPlacesChange(element));
			});
		}

		const attendeeTypeRadios = document.querySelectorAll('.js-attendee-radio-btn');
		const addressTypeRadios = document.querySelectorAll('.js-address-radio-btn');

		if (attendeeTypeRadios) {
			attendeeTypeRadios.forEach(element => {
				element.addEventListener('click', OnAttendeeRadioStateChange);
			});
		}

		if (backToDetailsBtn) {
			backToDetailsBtn.addEventListener('click', () => {
				document.querySelector('.js-step-3').style.display = 'none';
				document.querySelector('.js-step-2').style.display = 'block';

				setActiveStep('details');
			});
		}

		if (addressTypeRadios) {
			addressTypeRadios.forEach(element => {
				element.addEventListener('click', OnAddressRadioStateChange);
			});
		}
	}
}());
