import {debounce} from 'lodash';

(function () {
	const SEARCH_WRAPPER_WIDTH = 200;
	const REST_LINKS_BTN_WIDTH = 60;
	const DESKTOP_MIN_WIDTH = 1024;
	const courseBookingNavigation = document.querySelector('.navigation_course-booking');
	const linksWrapper = document.querySelector('.navigation__links-list_bottom');

	if (linksWrapper) {
		const links = linksWrapper.querySelectorAll('.navigation__link-item');
		const logo = document.querySelector('.navigation__logo-wrapper');
		const restLinksWrapper = document.querySelector('.navigation__rest-links-wrapper');
		const restLinksBtn = document.querySelector('.navigation__rest-links-btn');
		const restLinks = document.querySelector('.navigation__rest-links');

		if (courseBookingNavigation) {
			return;
		}

		const moveAllLinksToBottomWrapper = () => {
			links.forEach(link => linksWrapper.appendChild(link));
		};

		const fillBottomNavigation = () => {
			if (window.innerWidth < DESKTOP_MIN_WIDTH) {
				return;
			}

			moveAllLinksToBottomWrapper();

			const availableWrapperWidth = window.innerWidth - logo.getBoundingClientRect().width - SEARCH_WRAPPER_WIDTH - REST_LINKS_BTN_WIDTH;
			const linksWrapperWidth = linksWrapper.getBoundingClientRect().width;

			if (linksWrapperWidth > availableWrapperWidth) {
				let linksWidth = 0;

				restLinksWrapper.classList.add('navigation__rest-links-wrapper_visible');
				links.forEach(link => {
					const {width} = link.getBoundingClientRect();

					linksWidth += width;

					if (linksWidth > availableWrapperWidth) {
						restLinks.appendChild(link);
					} else {
						linksWrapper.appendChild(link);
					}
				});
			} else {
				moveAllLinksToBottomWrapper();
				restLinksWrapper.classList.remove('navigation__rest-links-wrapper_visible');
			}
		};

		if (window.innerWidth > DESKTOP_MIN_WIDTH) {
			fillBottomNavigation();
		}

		window.addEventListener('resize', debounce(fillBottomNavigation, 150), true);
		restLinksBtn.addEventListener('click', () => restLinks.classList.toggle('navigation__rest-links_visible'));
	}
}());
