(function () {
	const tilesWrapper = document.querySelector('.person-tiles');

	if (tilesWrapper) {
		const MAX_VISIBLE_TILES = 6;
		const BTN_EXPANDED_CLASS = 'person-tiles__show-more-btn_expanded';

		const moreTilesWrapper = document.querySelector('.person-tiles__tiles-wrapper_more-tiles');
		const tiles = Array.from(tilesWrapper.querySelectorAll('.person-tiles__item'));
		const showMoreBtn = tilesWrapper.querySelector('.person-tiles__show-more-btn');

		if (tiles.length <= MAX_VISIBLE_TILES) {
			showMoreBtn.classList.add('person-tiles__show-more-btn_hidden');
			return;
		}

		const moreTiles = tiles.slice(MAX_VISIBLE_TILES, tiles.length);

		moreTiles.forEach(tile => {
			moreTilesWrapper.appendChild(tile);
		});

		const moreTilesWrapperHeight = moreTilesWrapper.getBoundingClientRect().height;

		moreTilesWrapper.style.height = 0;

		showMoreBtn.addEventListener('click', () => {
			showMoreBtn.classList.toggle(BTN_EXPANDED_CLASS);

			if (showMoreBtn.classList.contains(BTN_EXPANDED_CLASS)) {
				moreTilesWrapper.style.height = `${moreTilesWrapperHeight}px`;
				showMoreBtn.innerHTML = 'Show less';
			} else {
				moreTilesWrapper.style.height = 0;
				showMoreBtn.innerHTML = 'Show more';
			}
		});
	}
}());
