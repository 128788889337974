(function () {
	(bookingDateSelectors => {
		[].forEach.call(bookingDateSelectors, item => {
			item.addEventListener('change', () => {
				const selectedOptions = item.selectedOptions[0];

				window.location = selectedOptions.getAttribute('data-url');
			});
		});
	})(document.querySelectorAll('.js-booking-date'));
}());
