import {tns} from 'tiny-slider/src/tiny-slider';

const insertAfter = (referenceNode, newNode) => {
	referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
};

const regenerateTilesGrid = () => {
	const gridColumns = [...document.querySelectorAll('.grid-tiles__column')];
	const gridSection = document.querySelector('.grid-tiles-section');
	const gridSectionRows = document.querySelectorAll('.grid-tiles__row');
	const firstTile = gridColumns[0].querySelectorAll('.grid-tile')[0];

	let allTiles = [];

	if (gridColumns.length > 0 && gridSection && gridSectionRows.length > 0 && firstTile) {
		const allTilesEXFirst = Array.from(gridColumns);

		allTilesEXFirst.forEach((column, index) => {
			let tiles = column.querySelectorAll('.grid-tile');

			// delete first element (fixed header)
			if (index === 0) tiles = Array.from(tiles).splice(1);

			tiles.forEach(tile => tile.classList.add('grid-tile_mob'));
			allTiles = allTiles.concat(...Array.from(tiles));
		});

		allTiles.forEach(tile => tile.classList.add('grid-tiles__column_mob'));

		gridSectionRows.forEach(row => gridSection.removeChild(row));

		const gridRow = document.createElement('div');
		const gridColumn = document.createElement('div');

		gridRow.classList.add('grid-tiles__row');
		gridColumn.classList.add('grid-tiles__column');
		gridColumn.classList.add('grid-tiles__column_heading');
		gridColumn.appendChild(firstTile.cloneNode(true));

		gridRow.append(gridColumn);
		gridSection.append(gridRow);

		const gridSectionMob = document.createElement('div');
		const gridSectionMobContainer = document.createElement('div');
		const arrow = document.createElement('div');

		arrow.classList.add('grid-tiles-section__arrow');

		gridSectionMobContainer.classList.add('grid-tiles-section__mob-container');

		gridSectionMob.classList.add('grid-tiles-section');
		gridSectionMob.classList.add('grid-tiles-section_mob-carousel');

		allTiles.forEach(obj => gridSectionMob.append(obj));

		gridSectionMobContainer.append(gridSectionMob);
		gridSectionMobContainer.append(arrow);
		insertAfter(gridSection, gridSectionMobContainer);
	}
};

(function () {
	const gridSection = document.querySelector('.grid-tiles-section');

	if (window.innerWidth < 700 && gridSection) {
		regenerateTilesGrid();
		const mobSection = document.querySelector('.grid-tiles-section_mob-carousel');

		if (mobSection) {
			const carousel = tns({
				container: '.grid-tiles-section_mob-carousel',
				loop: false,
				items: 1,
				slideBy: 'page',
				nav: false,
				mouseDrag: true,
				autoWidth: true,
				controls: false
			});

			const arrow = document.querySelector('.grid-tiles-section__arrow');

			arrow.addEventListener('click', () => carousel.goTo('next'));
		}
	}
}());
