const ENTER_KEY_CODE = 13;
const forms = Array.from(document.querySelectorAll('.navigation__search-form'));

forms.forEach(form => {
	const input = form.querySelector('input');

	form.addEventListener('keydown', e => {
		if (e.keyCode === ENTER_KEY_CODE) {
			e.preventDefault();
			if (input.value.length > 0) {
				window.location.href = `${form.action}?search=${input.value}`;
			}
		}
	});

	const submitBtn = form.querySelector('button[type="submit"]');

	if (submitBtn) {
		submitBtn.addEventListener('click', e => {
			e.preventDefault();
			if (input.value.length > 0) {
				window.location.href = `${form.action}?search=${input.value}`;
			}
		});
	}
});
