(function () {
	(tileLinks => {
		tileLinks.forEach(link => {
			link.addEventListener('mouseover', ev => {
				const closestElement = ev.target.closest('.grid-tile');
				const children = closestElement.querySelector(
					'.grid-tile__text-title'
				);

				children.classList.add('grid-tile__text-title_underline');
			});

			link.addEventListener('mouseout', ev => {
				const closestElement = ev.target.closest('.grid-tile');
				const children = closestElement.querySelector(
					'.grid-tile__text-title'
				);

				children.classList.remove('grid-tile__text-title_underline');
			});
		});
	})(document.querySelectorAll('.grid-tile__link'));
}());
