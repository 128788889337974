import YouTubePlayer from 'youtube-player';
import {v4 as uuidv4} from 'uuid';

const createYoutubePlayers = frameList => {
	frameList.forEach(elem => {
		if (elem.slide) {
			let slideIframeId = elem.slide.firstElementChild.id;

			// tiny-slider inserts two elements (clones) into the DOM on the right and left of the center
			if (elem.slide.classList.contains('tns-slide-cloned')) {
				slideIframeId += '_clone_' + uuidv4().slice(0, 10);
				elem.slide.firstElementChild.id = slideIframeId;
			}

			// The video player uses the item ID to insert the player
			// npm yt-player not supports playlist (!)
			const {videoId} = elem.slide.firstElementChild.dataset;

			if (videoId) {
				elem.player = new YouTubePlayer(slideIframeId, {
					videoId,
					height: '100%',
					width: '100%',
					playerVars: {
						autoplay: 1,
						controls: 0,
						showinfo: 0,
						rel: 0,
						fs: 0,
						loop: 1,
						mute: 1,
						playsinline: 1,
						playlist: videoId
					}
				});

				elem.player.playVideo();
			}
		} else {
			return 0;
		}
	});
};

export default createYoutubePlayers;
