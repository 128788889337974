import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

window.onload = function () {
	const PAGE_NAVIGATION_HEIGHT = 80;
	const courseSchedule = document.querySelector('.course-schedule');
	const bookButton = document.querySelector('a.cta[href="#book-now"]');

	if (courseSchedule && bookButton) {
		bookButton.addEventListener('click', e => {
			e.preventDefault();

			const positionTop = courseSchedule.getBoundingClientRect().top
				+ window.pageYOffset;
			const pageNavigationHeight = PAGE_NAVIGATION_HEIGHT;

			window.scrollTo({
				top: positionTop - pageNavigationHeight + 1,
				behavior: 'smooth'
			});
		});
	}
};
