(function () {
	((tilesGridSection, modalTriggers, bodyBlackout, popupModal, iframe) => {
		if (tilesGridSection) {
			modalTriggers.forEach(trigger => {
				trigger.addEventListener('click', event => {
					if (iframe) {
						iframe.setAttribute(
							'src',
							trigger.getAttribute('data-video-id')
						);
					} else {
						iframe = document.createElement('iframe');
						iframe.classList.add('grid-tiles-youtube-iframe');
						iframe.setAttribute('width', '100%');
						iframe.setAttribute('width', '100%');
						iframe.setAttribute('height', '100%');
						iframe.setAttribute(
							'src',
							trigger.getAttribute('data-video-id')
						);

						popupModal.append(iframe);
					}

					popupModal.classList.add('grid-tiles-popup-modal_isVisible');
					bodyBlackout.classList.add('grid-tiles-body-blackout_isBlackedout');

					bodyBlackout.style.height =	document.body.scrollHeight + 'px';
					popupModal.style.top = event.pageY + 'px';
					popupModal
						.querySelector('.grid-tiles-popup-modal__close')
						.addEventListener('click', () => {
							popupModal.classList.remove(
								'grid-tiles-popup-modal_isVisible'
							);
							bodyBlackout.classList.remove(
								'grid-tiles-body-blackout_isBlackedout'
							);
							iframe.setAttribute('src', '');
						});

					bodyBlackout.addEventListener('click', () => {
						popupModal.classList.remove('grid-tiles-popup-modal_isVisible');
						bodyBlackout.classList.remove(
							'grid-tiles-body-blackout_isBlackedout'
						);
						iframe.setAttribute('src', '');
					});
				});
			});
		}
	})(
		document.querySelector('.grid-tiles-section'),
		document.querySelectorAll('.grid-tile__video-container-play-btn'),
		document.querySelector('.grid-tiles-body-blackout'),
		document.querySelector('.grid-tiles-popup-modal'),
		document.querySelector('.grid-tiles-youtube-iframe')
	);
}());
