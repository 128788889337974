(function () {
	const tabs = Array.from(document.querySelectorAll('.customer-portal-hero__tab'));
	const addAttendeeBtn = document.querySelector('.js-add-attendee');

	if (addAttendeeBtn) {
		return;
	}

	if (tabs.length) {
		const TAB_ACTIVE_CLASS = 'customer-portal-hero__tab_active';
		const TAB_CONTENT_ACTIVE_CLASS = 'customer-portal-content_active';
		const DATA_ATTRIBUTE = 'data-tab-content';
		const heroTitle = document.querySelector('.customer-portal-hero__title');
		const tabContents = Array.from(document.querySelectorAll('.customer-portal-content'));
		const detailsLinks = Array.from(
			document.querySelectorAll('.customer-portal-content__action-link_order')
		);
		const ordersTabContent = document.querySelector('.customer-portal-content_orders');
		const backToOrdersLinks = document.querySelectorAll(
			'.customer-portal-content__action-link_back-to-orders'
		);

		const breadcrumbsContainer = document.querySelector('.breadcrumbs');
		const ordersBreadcrumb = document.createElement('div');

		ordersBreadcrumb.classList.add('breadcrumbs__item');
		ordersBreadcrumb.classList.add('breadcrumbs__item_orders');
		ordersBreadcrumb.innerHTML = '<a class="breadcrumbs__link" href="/">Orders</a>';

		const hideAllTabContents = () => {
			tabContents.forEach(t => t.classList.remove(TAB_CONTENT_ACTIVE_CLASS));
		};

		const showOrderDetails = order => {
			document.querySelector(`.customer-portal-content_details[data-order=${order}]`).classList.add(TAB_CONTENT_ACTIVE_CLASS);
			heroTitle.innerHTML = order;
		};

		const fromDetailsToOrders = e => {
			e.preventDefault();

			hideAllTabContents();
			ordersTabContent.classList.add(TAB_CONTENT_ACTIVE_CLASS);
			breadcrumbsContainer.removeChild(breadcrumbsContainer.querySelector('.breadcrumbs__item_orders'));
			heroTitle.innerHTML = 'My orders';
		};

		tabs.forEach(tab => {
			tab.addEventListener('click', () => {
				const activeTabContent = tabContents.find(t => t.classList.contains(TAB_CONTENT_ACTIVE_CLASS));

				if (activeTabContent.getAttribute(DATA_ATTRIBUTE) === tab.getAttribute(DATA_ATTRIBUTE)) {
					return;
				}

				tabs.forEach(t => t.classList.remove(TAB_ACTIVE_CLASS));
				hideAllTabContents();

				tab.classList.add(TAB_ACTIVE_CLASS);
				tabContents
					.find(t => t.getAttribute(DATA_ATTRIBUTE) === tab.getAttribute(DATA_ATTRIBUTE))
					.classList.add(TAB_CONTENT_ACTIVE_CLASS);
				heroTitle.innerHTML = tab.getAttribute(DATA_ATTRIBUTE) === 'courses' ? 'My courses' : 'My orders';
				if (breadcrumbsContainer.querySelector('.breadcrumbs__item_orders') !== null) {
					breadcrumbsContainer.removeChild(breadcrumbsContainer.querySelector('.breadcrumbs__item_orders'));
				}
			});
		});

		detailsLinks.forEach(link => {
			link.addEventListener('click', e => {
				e.preventDefault();

				hideAllTabContents();
				showOrderDetails(link.getAttribute('data-order'));
				breadcrumbsContainer.appendChild(ordersBreadcrumb);
				ordersBreadcrumb.querySelector('.breadcrumbs__link').addEventListener('click', fromDetailsToOrders);
			});
		});

		backToOrdersLinks.forEach(link => link.addEventListener('click', fromDetailsToOrders));
	}
}());
