import {tns} from 'tiny-slider/src/tiny-slider';
import createYoutubePlayers from './youtube-background';

(function () {
	((carousel, slides, pagination, leftNav, rightNav, frameWithYoutubeList) => {
		let frameYoutubeList = [];

		let slider, sliderTimings;

		let timerId = null;

		const initYoutubePlayers = () => {
			// if we not need background video on mobile
			if (frameWithYoutubeList && document.body.clientWidth > 700) {
				const frameList = document.querySelectorAll('.hero-carousel__item');

				frameYoutubeList = Array.from(frameList).map(slide => ({slide, player: undefined}));
				// to get control over the YouTube player items
				createYoutubePlayers(frameYoutubeList);
			}
		};

		const autoPlayController = () => {
			const info = slider.getInfo();
			const {displayIndex: index} = info;

			if (timerId != null) {
				clearTimeout(timerId);
				timerId = null;
			}
			timerId = setTimeout(() => {
				slider.goTo('next');
			}, sliderTimings[index - 1].timing * 1000);
		};

		// PAGINATION LOGIC
		const showActiveTab = () => {
			const tabs = document.querySelectorAll(
				'.hero-carousel__controls-pagination-tab'
			);

			const activeTab = document.querySelector('.tns-slide-active');
			const info = slider.getInfo();
			const {displayIndex} = info;

			autoPlayController();

			tabs.forEach((tab, i) => {
				if (i === displayIndex - 1 && activeTab) {
					tab.classList.add('hero-carousel__controls-pagination-tab_is-active');
				} else {
					tab.classList.remove('hero-carousel__controls-pagination-tab_is-active');
				}
			});
		};

		const moveByPaginationClick = e => {
			clearTimeout(timerId);
			const tabClicked = e.target;

			const toMoveSlideId = parseInt(
				tabClicked.getAttribute('data-tab'),
				10
			);

			const activeTab = document.querySelector('.tns-slide-active');

			if (!activeTab) {
				Array.from(slides)[toMoveSlideId].classList.add('tns-slide-active');
				slider.rebuild();
				setTimeout(() => {
					slider.goTo(toMoveSlideId + 1);
				}, 0);
			} else {
				slider.goTo(toMoveSlideId);
			}
		};

		const initPagination = () => {
			if (slides.length === 1) {
				pagination.style.display = 'none';
				leftNav.style.display = 'none';
				rightNav.style.display = 'none';
			} else {
				slides.forEach((slide, i) => {
					const tab = document.createElement('div');

					tab.classList.add('hero-carousel__controls-pagination-tab');
					tab.setAttribute('data-tab', i);
					tab.setAttribute('id', 'tab' + i);

					if (i === 0) {
						tab.classList.add(
							'hero-carousel__controls-pagination-tab_is-active'
						);
					}

					slide.setAttribute('data-slide-id', i);

					const slideCurrentNumber = document.createElement('span');

					slideCurrentNumber.classList.add(
						'hero-carousel__controls-pagination-tab-span'
					);
					slideCurrentNumber.append(i + 1 < 10 ? '0' + (i + 1) : i + 1);
					slideCurrentNumber.setAttribute('data-tab', i);
					tab.append(slideCurrentNumber);

					const slideText = document.createElement('div');

					slideText.setAttribute('data-tab', i);
					slideText.classList.add(
						'hero-carousel__controls-pagination-tab-title'
					);
					slideText.append(
						slide.querySelector('.hero-carousel__title').innerText
					);

					tab.append(slideText);
					pagination.append(tab);
				});

				const tabs = document
					.querySelectorAll('.hero-carousel__controls-pagination-tab');

				tabs.forEach((cur, index) => cur.addEventListener('click', ev => moveByPaginationClick(ev, index)));
			}
		};

		// INIT

		const initSliderAndYP = () => {
			// start autoplay
			if (slides.length > 1) {
				autoPlayController();
			}
			// when the animation ends - show active tab + init new autoplay timeout
			slider.events.on('indexChanged', showActiveTab);
			initYoutubePlayers();
		};

		if (carousel) {
			// get timings
			sliderTimings = Array.from(slides).map((slide, i) => ({
				index: i,
				timing: parseInt(
					slide.getAttribute('data-timing'),
					10
				)
			}));

			slider = tns({
				container: '.hero-carousel-content',
				loop: true,
				items: 1,
				slideBy: 1,
				nav: false,
				speed: 1000,
				controls: false,
				autoplayButtonOutput: false,
				mouseDrag: false,
				lazyload: true
			});

			initSliderAndYP();

			leftNav.addEventListener('click', () => {
				clearTimeout(timerId);
				slider.goTo('prev');
			});

			rightNav.addEventListener('click', () => {
				clearTimeout(timerId);
				slider.goTo('next');
			});

			initPagination();
		}
	})(
		document.querySelector('.hero-carousel'),
		document.querySelectorAll('.hero-carousel__item'),
		document.querySelector('.hero-carousel__controls-pagination'),
		document.querySelector('.hero-carousel__controls-nav-left'),
		document.querySelector('.hero-carousel__controls-nav-right'),
		document.querySelectorAll('.hero-carousel__item_youtube-video')
	);
}());
