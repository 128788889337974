import {debounce} from 'lodash';

((
	nav,
	navMenu,
	menuActiveClass,
	navTrigger,
	triggerActiveClass,
	dropdownBtn,
	navItemActiveClass,
	dropdownClass,
	dropdownVisibleClass
) => {
	if (nav) {
		const DESKTOP_MIN_WIDTH = 1024;
		const trigger = nav.querySelector(navTrigger);
		const menu = nav.querySelector(navMenu);
		const dropdownBtns = Array.from(nav.querySelectorAll(dropdownBtn));

		const hideMobileDropdowns = () => {
			if (window.innerWidth < DESKTOP_MIN_WIDTH) {
				return;
			}

			trigger.classList.remove(triggerActiveClass);
			menu.classList.remove(menuActiveClass);

			dropdownBtns.forEach(btn => {
				const dropdown = btn.parentElement.parentElement.querySelector(dropdownClass);

				btn.parentElement.classList.remove(navItemActiveClass);
				dropdown.classList.remove(dropdownVisibleClass);
			});
		};

		trigger.addEventListener('click', () => {
			trigger.classList.toggle(triggerActiveClass);
			menu.classList.toggle(menuActiveClass);
		});

		dropdownBtns.forEach(btn => {
			btn.addEventListener('click', () => {
				const dropdown = btn.parentElement.parentElement.querySelector(dropdownClass);

				btn.parentElement.classList.toggle(navItemActiveClass);
				dropdown.classList.toggle(dropdownVisibleClass);
			});
		});

		window.addEventListener('resize', debounce(hideMobileDropdowns, 150), true);
	}
})(
	document.querySelector('.navigation'),
	'.navigation__menu',
	'navigation__menu_opened',
	'.navigation__hamburger-btn',
	'navigation__hamburger-btn_opened',
	'.navigation__link-item-chevron-btn',
	'navigation__link-item-inner_active',
	'.navigation__dropdown',
	'navigation__dropdown_visible'
);
