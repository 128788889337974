import {debounce} from 'lodash';
import getPageWidth from '../../utils/get-page-width';
import getScrollbarWidth from '../../utils/get-scrollbar-width';

(function () {
	const carouseTilesContainers = Array.from(document.querySelectorAll('.js-carousel-tiles-container'));
	const DEBOUNCE_TIME = 150;
	const ACTIVE_SCROLL_CLASS = 'js-carousel-tiles-container_scroll-active';
	const DESKTOP_SCREEN_SIZE = 1024;

	carouseTilesContainers.forEach(carouseTilesContainer => {
		const tilesGap = Number(carouseTilesContainer.getAttribute('data-tiles-gap'));
		const isSpeakerBlock = carouseTilesContainer.classList.contains('speaker-section__speaker-container');
		const isRelatedCoursesBlock = carouseTilesContainer.classList.contains('related-courses-section__related-courses-container');

		let isDown = false;

		let startX, scrollLeft;

		const mouseDown = e => {
			isDown = true;
			carouseTilesContainer.classList.add(ACTIVE_SCROLL_CLASS);
			startX = e.pageX - carouseTilesContainer.offsetLeft;
			scrollLeft = carouseTilesContainer.scrollLeft;
		};

		const mouseLeave = () => {
			isDown = false;
			carouseTilesContainer.classList.remove(ACTIVE_SCROLL_CLASS);
		};

		const scrollTiles = () => {
			const SCROLLABLE_CLASS = 'js-carousel-tiles-container_scrollable';
			const tiles = Array.from(carouseTilesContainer.querySelectorAll('.js-carousel-tile'));
			const containerWidth = carouseTilesContainer.getBoundingClientRect().width;
			const tilesWidth = tiles.reduce((acc, tile) => {
				acc += tile.getBoundingClientRect().width;

				return acc;
			}, 0) + ((tiles.length - 1) * tilesGap);

			if ((containerWidth >= tilesWidth)
			|| ((isSpeakerBlock || isRelatedCoursesBlock) && (getPageWidth() + getScrollbarWidth() > DESKTOP_SCREEN_SIZE))) {
				carouseTilesContainer.classList.remove(SCROLLABLE_CLASS);
				carouseTilesContainer.removeEventListener('mousedown', mouseDown);
				return;
			}

			carouseTilesContainer.addEventListener('mousedown', mouseDown);

			carouseTilesContainer.addEventListener('mouseleave', mouseLeave);
			carouseTilesContainer.addEventListener('mouseup', mouseLeave);

			carouseTilesContainer.addEventListener('mousemove', e => {
				if (!isDown) {
					return;
				}

				e.preventDefault();
				const x = e.pageX - carouseTilesContainer.offsetLeft;
				const walk = x - startX;

				carouseTilesContainer.scrollLeft = scrollLeft - walk;
			});

			carouseTilesContainer.classList.add(SCROLLABLE_CLASS);
		};

		window.addEventListener('resize', debounce(scrollTiles, DEBOUNCE_TIME), true);

		scrollTiles();
	});
}());
