(function (pageNavigation, wrapper, wrapperOuter) {
	const ANCHOR_CLASS = 'page-navigation__wrapper-inner-link';
	const ANCHOR_ACTIVE_CLASS = 'page-navigation__wrapper-inner-link_active';

	let PAGE_NAVIGATION_HEIGHT = 0;

	let ticking = false;

	const getCurrentSection = (anchor, sections) => {
		const sectionId = anchor.getAttribute('href');
		const currentSection = sections.filter(
			s => `#${s.getAttribute('id')}` === sectionId
		)[0];

		return currentSection;
	};

	const handleLinksClick = (anchors, sections) => {
		anchors.forEach(anchor => {
			const currentSection = getCurrentSection(anchor, sections);

			anchor.addEventListener('click', event => {
				event.preventDefault();
				const positionTop = currentSection.offsetTop;

				const shiftUp = PAGE_NAVIGATION_HEIGHT * 2;

				window.scrollTo({
					top: positionTop - shiftUp,
					behavior: 'smooth'
				});
			});
		});
	};

	const fixedScrolling = (anchors, sections) => {
		const scrollTop = window.pageYOffset;

		anchors.forEach(anchor => {
			const currentSection = getCurrentSection(anchor, sections);

			const sectionPositionTop = currentSection.offsetTop;

			const sectionHeight = currentSection.offsetHeight;

			if (
				scrollTop + (PAGE_NAVIGATION_HEIGHT * 2) + 10 >= sectionPositionTop
				&& scrollTop + (PAGE_NAVIGATION_HEIGHT * 2) <= sectionHeight + sectionPositionTop
			) {
				if (!anchor.classList.contains(ANCHOR_ACTIVE_CLASS)) {
					anchor.classList.add(ANCHOR_ACTIVE_CLASS);
					if (window.outerWidth < 600) {
						// mobileSideSpace --> 24 px
						wrapperOuter.scrollTo({
							top: 0,
							left: anchor.offsetLeft - 24,
							behavior: 'smooth'
						});
					}
				}
			} else {
				anchor.classList.remove(ANCHOR_ACTIVE_CLASS);
			}
		});
	};

	const onScroll = (anchors, sections) => {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				fixedScrolling(anchors, sections);
				ticking = false;
			});

			ticking = true;
		}
	};

	const onMouseWheelIE = e => {
		e.preventDefault();
		const {wheelDelta} = e;
		const currentScrollPosition = window.pageYOffset;

		window.scrollTo(0, currentScrollPosition - wheelDelta);
	};

	if (pageNavigation) {
		const sections = [].slice.call(document.getElementsByTagName('div'));
		const sectionsWithTitles = sections.filter(s =>	s.getAttribute('data-navigation-title'));

		if (sectionsWithTitles.length > 0) {
			sectionsWithTitles.forEach(el => {
				const title = el.getAttribute('data-navigation-title');
				const link = document.createElement('a');

				link.classList.add(ANCHOR_CLASS);
				link.setAttribute('id', 'link_' + title);

				link.setAttribute('href', '#' + title);

				link.append(title);

				wrapper.append(link);
			});

			const anchors = [].slice.call(document.getElementsByClassName(ANCHOR_CLASS));

			handleLinksClick(anchors, sections);

			window.addEventListener('scroll', () => {
				onScroll(anchors, sections);
			});

			PAGE_NAVIGATION_HEIGHT = wrapper.offsetHeight;

			// ie
			if (document.documentMode) {
				document.body.addEventListener('mousewheel', onMouseWheelIE);
			}
		}
	}
}(
	document.querySelector('.page-navigation'),
	document.querySelector('.page-navigation__wrapper-inner'),
	document.querySelector('.page-navigation__wrapper')
));
