import {isEmpty} from 'lodash';

const REQUIRED_ERROR_MESSAGE = 'Please fill this field';
const EMAIL_ERROR_MESSAGE = 'Please enter a valid email address';
const ERROR_CLASS = 'attendee-form__error-input';
const INPUT_ERROR_CLASS = 'attendee-form__input_error';

function setError(input, msg) {
	const error = document.createElement('div');

	error.classList.add('attendee-form__error-input');
	error.innerHTML = msg;
	input.parentElement.appendChild(error);
	input.classList.add(INPUT_ERROR_CLASS);
}

function removeError(input) {
	const error = input.parentElement.querySelector(`.${ERROR_CLASS}`);

	if (error) {
		input.parentElement.removeChild(error);
		input.classList.remove(INPUT_ERROR_CLASS);
	}
}

function removeErrors(inputs) {
	inputs.forEach(input => removeError(input));
}

function validateEmail(email) {
	// eslint-disable-next-line
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return re.test(email);
}

function validate() {
	const inputs = Array.from(document.querySelectorAll('#attendee-form .attendee-form__input'));

	removeErrors(inputs);

	const errors = inputs.reduce((acc, input) => {
		if (!input.value && input.id !== 'email') {
			acc[input.id] = REQUIRED_ERROR_MESSAGE;
			setError(input, REQUIRED_ERROR_MESSAGE);
		}

		return acc;
	}, {});

	const emailInput = document.getElementById('email');

	if (validateEmail(emailInput.value) === false) {
		errors.email = EMAIL_ERROR_MESSAGE;
		setError(emailInput, EMAIL_ERROR_MESSAGE);
	}

	return {
		isValid: !!isEmpty(errors)
	};
}

Array.from(document.querySelectorAll('.attendee-form__input')).forEach(input => {
	input.addEventListener('input', () => {
		removeError(input);
		if (input.id === 'email') {
			if (validateEmail(input.value)) {
				removeError(input);
			} else {
				setError(input, EMAIL_ERROR_MESSAGE);
			}
		} else if (input.value) {
			removeError(input);
		} else {
			setError(input, REQUIRED_ERROR_MESSAGE);
		}
	});
});

export default validate;
