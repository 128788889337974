import fetch from 'unfetch';
import validate from './validate';

(function () {
	const addAttendeeBtn = document.querySelector('.js-add-attendee');

	if (!addAttendeeBtn) {
		return;
	}

	const SUCCESS_STATUS = 200;
	const UNAUTHORIZED = 401;
  const ACCESS_DENIED = 403;
  const DUPLICATE = 409;
	const FORM_VISIBLE_CLASS = 'attendee-form_visible';

	const ERROR_MSG = 'Something went wrong, please try again later';
	const errorMsg = document.querySelector('.customer-portal-content__attendee-error-msg');
	const overlay = document.querySelector('.attendee-form__loading-overlay');
	const orderId = document
		.querySelector('.customer-portal-content')
		.getAttribute('data-order-id');
	const formsContainer = document.querySelector('.customer-portal-content__forms-container');
	const addAttendeeForm = document.querySelector('#attendee-form');
	const addMyselfForm = document.querySelector('#attendee-myself-form');
	const submitAttendeeFormBtn = document.querySelector('.js-add-attendee-btn');
	const submitAttendeeMyselfFormBtn = document.querySelector('.js-add-attendee-myself-btn');
	const tableBody = document.querySelector('.customer-portal-content__table-body');
	const mobileTableBody = document.querySelector('.customer-portal-content__mobile-tables-wrapper');
	const addMore = document.querySelector('.customer-portal-content__add-more');
	const showFormBtns = Array.from(document.querySelectorAll('.customer-portal-content__cta'));
	const closeFormBtns = Array.from(document.querySelectorAll('.js-attendee-form-close'));
	const ticketsDropdown = document.getElementById('ticket');
	const ticketsDropdownMyself = document.getElementById('ticket-myself');

	const setLoading = () => {
		overlay.style.display = 'block';
	};

	const removeLoading = () => {
		overlay.style.display = 'none';
	};

	const changeBtnsState = (disable = false) => {
		showFormBtns.forEach(btn => {
			btn.disabled = !!disable;
		});
	};

	const updateList = () => {
		setLoading();
		tableBody.innerHTML = '';
		mobileTableBody.innerHTML = '';
		addMore.innerHTML = '';
		fetch(`/Umbraco/Api/Attendees/List?orderId=${orderId}`)
			.then(r => r.json())
			.then(data => {
				const {freePlacesCount, courseRegistrations, tickets} = data;

				if (freePlacesCount > 0) {
					addMore.innerHTML = `You need to add ${freePlacesCount} more attendee${freePlacesCount > 1 ? 's' : ''}`;
					formsContainer.classList.remove('customer-portal-content__forms-container_hidden');
				} else {
					addMore.innerHTML = 'No free places left';
					formsContainer.classList.add('customer-portal-content__forms-container_hidden');
				}
				courseRegistrations.forEach(p => {
					const entry = document.createElement('tr');
					const mobileTable = document.createElement('div');

					entry.innerHTML = `
						<td>
							${p.name}
						</td>

						<td>
							<a href="mailto:${p.email}">${p.email}</a>
						</td>

						<td>
							${p.company}
						</td>

						<td>
							${p.jobTitle}
						</td>

						<td>
							${p.ticketName}
						</td>

						<td>
							<a href="#" class="customer-portal-content__action-link customer-portal-content__action-link_remove-attendee js-remove-attendee" data-id=${p.id}>Remove</a>
						</td>`;

					tableBody.appendChild(entry);

					mobileTable.classList.add('customer-portal-content__mobile-table');

					mobileTable.innerHTML = `
					<div class="customer-portal-content__mobile-table-row">
						<div class="customer-portal-content__mobile-table-cell">
							Name
						</div>

						<div class="customer-portal-content__mobile-table-cell">
							${p.name}
						</div>
					</div>
					<div class="customer-portal-content__mobile-table-row">
						<div class="customer-portal-content__mobile-table-cell">
							Email
						</div>

						<div class="customer-portal-content__mobile-table-cell">
							<a href="mailto:${p.email}">${p.email}</a>
						</div>
					</div>
					<div class="customer-portal-content__mobile-table-row">
						<div class="customer-portal-content__mobile-table-cell">
							Company
						</div>

						<div class="customer-portal-content__mobile-table-cell">
							${p.company}
						</div>
					</div>
					<div class="customer-portal-content__mobile-table-row">
						<div class="customer-portal-content__mobile-table-cell">
							Job title
						</div>

						<div class="customer-portal-content__mobile-table-cell">
							${p.jobTitle}
						</div>
					</div>
					<div class="customer-portal-content__mobile-table-row">
						<div class="customer-portal-content__mobile-table-cell">
							Ticket name
						</div>

						<div class="customer-portal-content__mobile-table-cell">
							${p.ticketName}
						</div>
					</div>
					<a href="#" class="js-remove-attendee customer-portal-content__action-link customer-portal-content__action-link_remove-attendee" data-id=${p.id}>
						Remove
					</a>`;

					mobileTableBody.append(mobileTable);
				});
				ticketsDropdown.innerHTML = '';
				ticketsDropdownMyself.innerHTML = '';
				tickets.forEach(t => {
					const option = document.createElement('option');

					option.setAttribute('value', t.id);
					option.innerText = t.name;

					const optionClone = option.cloneNode(true);

					ticketsDropdown.appendChild(option);
					ticketsDropdownMyself.appendChild(optionClone);
				});
				const removeBtns = document.querySelectorAll('.js-remove-attendee');

				removeBtns.forEach(btn => {
					btn.addEventListener('click', event => {
						event.preventDefault();
						setLoading();

						const payload = {
							courseRegistrationId: btn.getAttribute('data-id'),
                            orderId: orderId
						};

						fetch('/Umbraco/Api/Attendees/Remove', {
							method: 'POST',
							headers: {
								Accept: 'application/json',
								'Content-Type': 'application/json'
							},
							credentials: 'include',
							body: JSON.stringify(payload)
						})
							.then(res => {
								if (res.status === SUCCESS_STATUS) {
									updateList();
								} else	if (res.status === UNAUTHORIZED) {
									errorMsg.innerHTML = 'Authorization expired. Please refresh the page and try again.';
									removeLoading();
								} else if (res.status === ACCESS_DENIED) {
									errorMsg.innerHTML = 'You do not have rights to remove an attendee (Access denied). Please refresh the page and try again. If this does not help, please contact an administrator.';
									removeLoading();
								} else {
									errorMsg.innerHTML = ERROR_MSG;
									removeLoading();
								}
							})
							.catch(e => {
								errorMsg.innerHTML = e;
								removeLoading();
							});
					});
				});
				removeLoading();
			})
			.catch(() => {
				errorMsg.innerHTML = ERROR_MSG;
				removeLoading();
			});
	};

	const addAttendee = e => {
		e.preventDefault();

		const {isValid} = validate();

		if (!isValid) {
			return;
		}

		setLoading();
		const data = {
			orderId,
			Firstname: document.getElementById('first-name').value,
			Lastname: document.getElementById('last-name').value,
			email: document.getElementById('email').value,
			company: document.getElementById('company').value,
			Phone: document.getElementById('phone').value,
			JobTitle: document.getElementById('job').value,
			TicketId: document.getElementById('ticket').value
		};

		fetch('/umbraco/Api/Attendees/Add', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify(data)
		})
			.then(async res => {
				if (res.status === SUCCESS_STATUS) {
					updateList();
					addAttendeeForm.classList.remove(FORM_VISIBLE_CLASS);
					changeBtnsState();
				} else if (res.status === UNAUTHORIZED) {
					errorMsg.innerHTML = 'Authorization expired. Please refresh the page and try again.';
					removeLoading();
				} else if (res.status === ACCESS_DENIED) {
					errorMsg.innerHTML = 'You do not have rights to add an attendee (Access denied). Please refresh the page and try again. If this does not help, please contact an administrator.';
          removeLoading();
        } else if (res.status === DUPLICATE) {
          errorMsg.innerHTML = 'A user with this email address is already an attendee of this course.';
          removeLoading();
        } else {
					errorMsg.innerHTML = await res.text() || ERROR_MSG;
					removeLoading();
				}
			})
			.catch(err => {
				errorMsg.innerHTML = err;
				removeLoading();
			});
	};

	const addMyself = event => {
		event.preventDefault();

		setLoading();

		const data = {
			orderId,
			TicketId: document.getElementById('ticket-myself').value
		};

		fetch('/Umbraco/Api/Attendees/AddCurrentContact', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify(data)
		})
			.then(async res => {
        if (res.status === SUCCESS_STATUS) {
          updateList();
          addMyselfForm.classList.remove(FORM_VISIBLE_CLASS);
          changeBtnsState();
        } else if (res.status === UNAUTHORIZED) {
          errorMsg.innerHTML = 'Authorization expired. Please refresh the page and try again.';
          removeLoading();
        } else if (res.status === ACCESS_DENIED) {
          errorMsg.innerHTML = 'You do not have rights to add yourself as an attendee (Access denied). Please refresh the page and try again. If this does not help, please contact an administrator.';
          removeLoading();
        } else if (res.status === DUPLICATE) {
          errorMsg.innerHTML = 'You are already an attendee of this course.';
          removeLoading();
				} else {
					errorMsg.innerHTML = await res.text() || ERROR_MSG;
					removeLoading();
				}
			})
			.catch(e => {
				errorMsg.innerHTML = e;
				removeLoading();
			});
	};

	showFormBtns.forEach(btn => {
		btn.addEventListener('click', () => {
			const form = btn.parentElement.querySelector('.customer-portal-content__attendee-form');

			changeBtnsState(true);
			form.classList.add(FORM_VISIBLE_CLASS);
		});
	});

	submitAttendeeFormBtn.addEventListener('click', addAttendee);
	submitAttendeeMyselfFormBtn.addEventListener('click', addMyself);

	closeFormBtns.forEach(btn => {
		btn.addEventListener('click', e => {
			e.preventDefault();

			const form = btn.parentElement;

			form.classList.remove(FORM_VISIBLE_CLASS);
			errorMsg.innerHTML = '';
			changeBtnsState();
		});
	});

	if (document.readyState !== 'loading') {
		updateList();
	} else {
		document.addEventListener('DOMContentLoaded', () => {
			updateList();
		});
	}
}());
